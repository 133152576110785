<template>
  <div>
    <v-card v-if="! hasPagePermission">
      <v-card-text class="text-center">
        <div class="title mb-3">Upgrade your subscription</div>

        <subscription-crown-icon size="50"></subscription-crown-icon>
        <br/><br/>

        <p>
          See and save specific posts from influencers to your campaigns, <br/> and improve your insights into how many impressions your collabs can generate.
          <br/>

          <a href="https://intercom.help/Make-influence-com/en/articles/6248346-how-does-the-content-feature-work" target="_blank">
            Learn more about the benefits of the "Influencer content" feature
          </a>
        </p>
      </v-card-text>
    </v-card>

    <v-card v-if="hasPagePermission">
      <v-card-title>
        <div>
          <div class="headline">
            Instagram report

            <v-chip
               color="#3AC2D2"
               class="white--text"
               x-small
            >
              Beta
            </v-chip>
          </div>
          <span class="subtitle-1 light-grey">
            <a href="https://intercom.help/Make-influence-com/da/articles/6248329-forsta-din-instagram-rapport" target="_blank">
              Read how</a>, you measure your results on content from Instagram
          </span>
        </div>
        <v-spacer></v-spacer>

        <v-tooltip :disabled="$vuetify.breakpoint.xsOnly" left>
          <template v-slot:activator="{ on }">
            <v-badge overlap color="badges">
              <template v-if="filtersCount > 0" v-slot:badge>
                {{ filtersCount }}
              </template>
              <v-btn
                 v-on="on"
                 icon
                 outlined
                 color="primary"
                 @click="
                  filtersTmp = cloneDeep(filters);
                  filterDialog = true;
                "
              >
                <v-icon small>fas fa-filter</v-icon>
              </v-btn>
            </v-badge>
          </template>
          <span>Filter</span>
        </v-tooltip>
      </v-card-title>
      <v-card-text>
        <v-container fluid grid-list-xl class="p-0">
          <v-layout wrap>
            <v-flex xs12 sm6 md6 xl4>
              <stat-widget
                 icon="fal fa-bullseye"
                 :title="$options.filters.local_numbers(summerizedStats.reach)"
                 subtitle="Reach"
                 flat
                 bordered
              ></stat-widget>
            </v-flex>
            <v-flex xs12 sm6 md6 xl4>
              <stat-widget
                 icon="fal fa-eye"
                 :title="$options.filters.local_numbers(summerizedStats.impressions)"
                 subtitle="Impressions"
                 flat
                 bordered
              ></stat-widget>
            </v-flex>
            <v-flex xs12 sm4 md4 xl4>
              <stat-widget
                 icon="fal fa-users"
                 :title="$options.filters.local_numbers(summerizedStats.influencers)"
                 subtitle="Influencers"
                 flat
                 bordered
              ></stat-widget>
            </v-flex>
            <v-flex xs12 sm4 md4 xl4>
              <stat-widget
                 icon="fal fa-plus-circle"
                 :title="$options.filters.local_numbers(summerizedStats.story_posts)"
                 subtitle="Story posts"
                 flat
                 bordered
              ></stat-widget>
            </v-flex>
            <v-flex xs12 sm4 md4 xl4>
              <stat-widget
                 icon="fal fa-images"
                 :title="$options.filters.local_numbers(summerizedStats.feed_posts)"
                 subtitle="Feed posts"
                 flat
                 bordered
              ></stat-widget>
            </v-flex>
          </v-layout>
        </v-container>
        <br/><br/>

        <instagram-report-data-table
          :items="influencerStats"
          :has-permission="hasInstagramInfluencerReportPermission"
        ></instagram-report-data-table>
      </v-card-text>
    </v-card>

    <v-dialog v-model="filterDialog" max-width="550px">
      <v-card>
        <v-card-title class="headline" primary-title>
          Filter report
        </v-card-title>
        <v-card-text>
<!--          <campaign-autocomplete-->
<!--             v-model="filtersTmp.campaign_uuid"-->
<!--             :return-object="false"-->
<!--          ></campaign-autocomplete>-->
          <date-picker
             v-if="filtersTmp.start_date"
             v-model="filtersTmp.start_date"
             label="Period start"
             cancel-button
             :disabled="hasLimitedSummerizedReportPermission"
             :reset-button="false"
          ></date-picker>
          <date-picker
             v-if="filtersTmp.end_date"
             v-model="filtersTmp.end_date"
             label="Period end"
             cancel-button
             :disabled="hasLimitedSummerizedReportPermission"
             :reset-button="false"
          ></date-picker>

          <template v-if="hasLimitedSummerizedReportPermission">
            <subscription-crown-icon>
            </subscription-crown-icon>

            Upgrade your subscription to see longer time period
          </template>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="filterDialog = false;">
            Close
          </v-btn>
          <v-btn
             color="primary"
             :disabled="hasLimitedSummerizedReportPermission"
             @click="
              filters = JSON.parse(JSON.stringify(filtersTmp));
              filterDialog = false;
            "
          >
            Filter
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import search_filters_url from "@/helpers/mixins/search_filters_url";
import local_numbers from "@/helpers/filters/local_numbers";
// import SavedMediaCard from "../../cards/SavedMediaCard2";
// import CampaignAutocomplete from "../autocomplete/CampaignAutocomplete";
// import InfluencerAutocomplete from "../autocomplete/InfluencerAutocomplete";
import SubscriptionCrownIcon from "../icon/SubscriptionCrownIcon";
import StatWidget from "../../widgets/StatWidget";
import InstagramReportDataTable from "../../data-tables/InstagramReportDataTable";
import DatePicker from "../../common/filters/DatePicker";

export default {
  components: {
    DatePicker,
    InstagramReportDataTable,
    StatWidget,
    // CampaignAutocomplete,
    // InfluencerAutocomplete,
    // SavedMediaCard,
    SubscriptionCrownIcon
  },
  mixins: [search_filters_url],
  filters: {local_numbers},
  data() {
    return {
      summerizedStats: {},
      influencerStats: [],
      filterDialog: false,
      filtersTmp: {},
      filters: {}
    };
  },
  computed: {
    ...mapGetters('subscriptions', ['hasPermission']),
    hasPagePermission() {
      return this.hasPermission('content_pages');
    },
    hasPermissionContentReporting() {
      return this.hasPermission('content_reporting');
    },
    hasInstagramInfluencerReportPermission() {
      return this.hasPermission('content_instagram_influencer_report');
    },
    hasLimitedSummerizedReportPermission() {
      return ! this.hasPermission('instagram_summerized_report');
    },
    limitedDaysForSummerizedReport() {
      return 14;
    },
    filtersCount() {
      return Object.keys(this.filters).length;
    }
  },
  watch: {
    filters: {
      handler: function() {
        this.setUrlSearchFilters(this.filters);
        this.getStats();
      },
      deep: true
    }
  },
  methods: {
    ...mapActions("core", ["getInstagramReportSummerized", "getInstagramReportInfluencers"]),
    getStats() {
      if(! this.hasPagePermission) {
        return;
      }

      let params = {
        posted_start_date: this.filters.start_date,
        posted_end_date: this.filters.end_date,
      };

      if(this.hasLimitedSummerizedReportPermission) {
        params.posted_start_date = this.$moment()
           .subtract(this.limitedDaysForSummerizedReport, "days")
           .format("YYYY-MM-DD");

        params.posted_end_date = this.$moment().format("YYYY-MM-DD");
      }

      this.getInstagramReportSummerized(params).then(response => {
        this.summerizedStats = response;
      });

      if(this.hasInstagramInfluencerReportPermission) {
        this.getInstagramReportInfluencers(params).then(response => {
          this.influencerStats = response.data;
        });
      }
    }
  },
  created() {
    this.filters = this.getUrlSearchFilters();
    if (Object.keys(this.filters).length === 0) {
      let days = 30;

      if(this.hasLimitedSummerizedReportPermission) {
        days = this.limitedDaysForSummerizedReport;
      }

      this.filters.start_date = this.$moment()
         .subtract(days, "days")
         .format("YYYY-MM-DD");
      this.filters.end_date = this.$moment().format("YYYY-MM-DD");
    }
  },
};
</script>

<style scoped></style>
