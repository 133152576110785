<template>
  <v-data-table
    :headers="headers"
    :items="items"
    :loading="loading"
    sort-by="impressions"
    hide-default-footer
    :items-per-page="10000"
  >
    <v-progress-linear
      slot="progress"
      color="primary"
      indeterminate
    ></v-progress-linear>

    <template v-slot:body="{ items }">
      <tbody>
        <tr v-if="! hasPermission">
          <td :colspan="headers.length" class="text-center font-weight-bold">
            <a href="https://www.makeinfluence.com/en/business/pricing" target="_blank" style="text-decoration: none;">
              <subscription-crown-icon></subscription-crown-icon> Upgrade
            </a>
          </td>
        </tr>
        <tr v-else v-for="item in items" :key="item.influencer_id">
          <td class="word-no-wrap">
            {{ item.first_name }} {{ item.last_name }}
          </td>
          <td class="text-right">{{ item.posts | local_numbers }}</td>
          <td class="text-right">{{ item.feed_posts | local_numbers }}</td>
          <td class="text-right">{{ item.story_posts | local_numbers }}</td>
          <td class="text-right">{{ item.reach | local_numbers }}</td>
          <td class="text-right">{{ item.impressions | local_numbers }}</td>
          <td class="text-right">{{ item.engagement | local_numbers }}</td>
        </tr>
      </tbody>
    </template>
  </v-data-table>
</template>

<script>
import local_numbers from "@/helpers/filters/local_numbers";
import utc_to_local_datetime from "@/helpers/filters/utc_to_local_datetime";
import SubscriptionCrownIcon from "../business/icon/SubscriptionCrownIcon";

export default {
  components: {SubscriptionCrownIcon},
  filters: {
    local_numbers,
    utc_to_local_datetime
  },
  props: {
    items: {
      type: Array,
      required: false,
      default: () => {
        return [];
      }
    },
    loading: {
      type: Boolean,
      required: false,
      default: false
    },
    hasPermission: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: () => ({
    headers: [
      {
        text: "Influencer",
        align: "left",
        // sortable: false,
        value: "first_name"
      },
      {
        text: "Posts",
        align: "right",
        // sortable: false,
        value: "posts"
      },
      {
        text: "Feed posts",
        align: "right",
        // sortable: false,
        value: "feed_posts"
      },
      {
        text: "Story posts",
        align: "right",
        // sortable: true,
        value: "story_posts"
      },
      {
        text: "Reach",
        align: "right",
        // sortable: true,
        value: "reach"
      },
      {
        text: "Impressions",
        align: "right",
        // sortable: true,
        value: "impressions"
      },
      {
        text: "Engagement",
        align: "right",
        // sortable: true,
        value: "engagement"
      }
    ]
  }),
};
</script>
